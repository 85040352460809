import JwtDecode from "jwt-decode";
import {Usuario} from "./user";


let user: number[];
try {
  user = (JwtDecode(new Usuario().token() || "") as any).rol;
} catch (e) {
  user = [];
}

export enum Permisos {
  admin = 0, // Gestion de permisos
  impersonar = 9, // Impersonar usuarios

  /** Operativos */
  gestionOperativos = 1, // Permite crear operativos, y ver sus datos al completo
  gestionCoordinacion = 10,
  revisarOperativos = 2, // Recibe y aprueba (o rechaza) los operativos para finalizar los informes
  notificarParticipantes = 11,
  notificarHorasTrimestre = 18,

  /** Voluntarios */
  gestionVoluntarios = 3, // Permite crear y editar cualquier voluntario
  verEstadisticasVoluntarios = 4, // Permite ver las estadisticas de cualquier voluntario
  gestionUniformidad = 21, // Permite añadir nueva uniformidad para los voluntarios

  /** Vehiculos */
  gestionVehiculos = 5, // Permite crear y editar los vehiculos
  gestionPartes = 6, // Permite ver todos los partes de vehiculos

  /** Material */
  gestionMaterial = 7, // Permite crear y modificar el material

  /** Alertas 112 */
  gestionAlertas = 8, // Permite crear y modificar las alertas del 112
  gestionAvisos = 19, // Permite crear y visualizar los avisos de particulares recibidas en el teléfono

  /** Noticias */
  gestionNoticias = 12, // Permite crear noticias

  /** Nido */
  verHistorialNido = 13, // Permite ver la gente que estaba en nido a cualquier hora
  notificarNido = 14, // Recibe notificaciones de cambios en la gente en nido

  /** Formacion */
  gestionFormacion = 15, // Permite crear cursos de formaci�n
  notificarFormacion = 16,

  /** RRSSS */
  gestionRss = 17,

}


export const permiso = (permiso: number): boolean => {
  if (!Object.values(Permisos).includes(permiso))
    return false;

  return user.includes(permiso);
};
